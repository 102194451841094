import React from 'react';
import moment from 'moment';
import { Typography } from 'antd';

import { ActivityDirection, ActivityModel, InboxDirection, VariableType } from '../../../../../../api';
import SbPanel from '../../../../components/common/SbPanel';
import SbIcon from '../../../../components/common/SbIcon';
import { TriggerDescriptions, TriggerNames } from '../../../../utils/dialogs';

const DATE_FORMAT = 'DD.MM.YYYY в HH:mm';
const MAIN_CLASS = 'sb-dialogs-card__content__panel-container__info';

interface IMessageInfoProps {
  activity: ActivityModel;
  getRecognizerResult: (activity: ActivityModel) => ActivityModel | undefined;
  getSigmaRecognizerResult: (activity: ActivityModel) => ActivityModel | undefined;
  getScenarioInfo: (activity: ActivityModel) => ActivityModel | undefined;
  getTriggerInfo: (activity: ActivityModel) => ActivityModel | undefined;
}

const MessageInfo: React.FC<IMessageInfoProps> = ({
  activity,
  getRecognizerResult,
  getSigmaRecognizerResult,
  getScenarioInfo,
  getTriggerInfo,
}) => {
  const recognizerResult = getRecognizerResult(activity);
  const sigmaRecognizerResult = getSigmaRecognizerResult(activity);
  const scenarioStarted = getScenarioInfo(activity);
  const triggerFired = getTriggerInfo(activity);

  const renderVariableValue = (type: VariableType, value: unknown) => {
    switch (type) {
      case 'Boolean':
        return <>{value ? 'Да' : 'Нет'}</>;
      case 'Number':
      case 'String':
        return <>{value as string}</>;
      case 'ComplexObject':
        return <pre>{JSON.stringify(value, null, 2)}</pre>;
      case 'DateTime':
        return <>{new Date((value as Array<{ Value: string; Timex: string }>)[0].Timex).toLocaleString()}</>;
      case 'PersonName': {
        const fullName = value as { first: string; last: string; middle: string };
        return (
          <>
            {fullName.last} {fullName.first} {fullName.middle}
          </>
        );
      }
      case 'File':
        return (
          <a download href={(value as { contentUrl: string }).contentUrl ?? ''}>
            <SbIcon iconName="download" size={16} />
            {(value as { name: string }).name ?? ''}
          </a>
        );
    }
  };

  return (
    <>
      <Typography.Title ellipsis level={4}>
        Подробнее о сообщении
      </Typography.Title>
      <SbPanel sbType="help">
        <div className={`${MAIN_CLASS}__secondary-info`}>
          {activity.properties.direction === InboxDirection.Internal
            ? 'От оператора (скрытое)' // TODO: пока что сообщениями от оператора считаются только скрытые сообщения
            : activity.direction == ActivityDirection.Inbound
            ? 'От пользователя'
            : 'От бота (markdown)'}
        </div>
        <div className={`${MAIN_CLASS}__text`}>{activity.text || '(пустое сообщение)'}</div>
        <div className={`${MAIN_CLASS}__secondary-info`}>{moment(activity.date).format(DATE_FORMAT)}</div>
      </SbPanel>
      {scenarioStarted && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="adjacent-item" size={20} />
            <div>Сценарий</div>
          </div>
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{scenarioStarted.text}</div>
          </div>
        </>
      )}
      {triggerFired?.text && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="lightning" size={20} />
            <div>Триггер</div>
          </div>
          <div className={`${MAIN_CLASS}__border-frame`}>
            <div className={`${MAIN_CLASS}__border-frame__text`}>{TriggerDescriptions[triggerFired.text]}</div>
          </div>
        </>
      )}
      {triggerFired?.text == TriggerNames.ExternalEvent && triggerFired?.value?.externalEventRawVariables && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="share-three" size={20} />
            <div>Переменные внешнего события</div>
          </div>
          <div className={`${MAIN_CLASS}__border-frame`}>
            {Object.values<{ Name: string; Type: VariableType; Value: unknown }>(
              triggerFired.value.externalEventRawVariables
            ).map((value, index) => {
              if (value.Name === undefined) return;
              return (
                <div key={index}>
                  {value.Type && <span className={`${MAIN_CLASS}__border-frame__variable-name`}>{value.Name}</span>}
                  <div className={`${MAIN_CLASS}__border-frame__variable-value`}>
                    {renderVariableValue(value.Type, value.Value)}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {!!activity.attachments?.length && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="paperclip" size={20} />
            <div>Вложения</div>
          </div>
          <div className={`${MAIN_CLASS}__attachments-container`}>
            {activity.attachments.map((m) => (
              <div key={m.contentUrl ?? ''} className={`${MAIN_CLASS}__attachments-container__attachment`}>
                <a download href={m.contentUrl ?? ''}>
                  <SbIcon iconName="download" size={16} />
                  {m.name}
                </a>
              </div>
            ))}
          </div>
        </>
      )}
      {!!activity.suggestedActions?.length && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="vertical-tidy-up" size={20} />
            <div>Кнопки</div>
          </div>
          <div className={`${MAIN_CLASS}__buttons-container`}>
            {activity.suggestedActions.map((m) => (
              <div key={m} className={`${MAIN_CLASS}__buttons-container__button`}>
                <div className={`${MAIN_CLASS}__buttons-container__button__text`}>{m}</div>
              </div>
            ))}
          </div>
        </>
      )}
      {!!sigmaRecognizerResult?.value?.results?.length && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="book-one" size={20} />
            <div>База знаний</div>
          </div>
          <div className={`${MAIN_CLASS}__description`}>Найден подходящий ответ</div>
          {sigmaRecognizerResult?.value.results.map(
            (result: { score: number; answer: string; question: string; questions: Array<string> }, index: number) => (
              <div key={index} className={`${MAIN_CLASS}__intent-frame`}>
                <div className={`${MAIN_CLASS}__intent-frame__item`}>
                  <div className={`${MAIN_CLASS}__intent-frame__item__name`}>
                    {!result.question
                      ? result.questions.map((question: string, index: number) => <div key={index}>{question}</div>)
                      : result.question}
                  </div>
                  <div className={`${MAIN_CLASS}__intent-frame__item__score`}>{result.score.toFixed(3)}</div>
                </div>
                <div
                  className={`${MAIN_CLASS}__intent-frame__divider`}
                  style={{ width: `calc(100% * ${result.score})` }}
                />
                <div className={`${MAIN_CLASS}__intent-frame__description`}>{result.answer}</div>
              </div>
            )
          )}
        </>
      )}
      {!!recognizerResult?.value?.Intents?.length && (
        <>
          <div className={`${MAIN_CLASS}__additional-title`}>
            <SbIcon iconName="adjacent-item" size={20} />
            <div>Распознанные интенты</div>
          </div>
          {recognizerResult.value.Intents.map((m: { Id: string; Name: string; Score: number }) => (
            <div key={m.Id} className={`${MAIN_CLASS}__intent-frame`}>
              <div className={`${MAIN_CLASS}__intent-frame__item`}>
                <div className={`${MAIN_CLASS}__intent-frame__item__name`}>{m.Name}</div>
                <div className={`${MAIN_CLASS}__intent-frame__item__score`}>{m.Score.toFixed(3)}</div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default MessageInfo;
